import React from "react";
import { BatteryRiskIcon } from "Styles/Icons/DesignSystem/BatteryRiskIcon";
import theme from "./../../../Styles/theme.scss";
import { AugmentDeviceData, SensorStatus } from "./types";

import { CircleCheckIcon } from "Styles/Icons/DesignSystem/CircleCheckIcon";
import { SignalDisconnectedIcon } from "Styles/Icons/DesignSystem/SignalDisconnectedIcon";
import { checkSensorBatteryWarning } from "./checkSensorBatteryWarning";

export const determineSensorStatus = (
  device: AugmentDeviceData,
  isHubOnline: boolean,
  isDesktop: boolean
): SensorStatus => {
  const isBatteryLow = checkSensorBatteryWarning(device);

  const deviceOffline = device?.current_status === "down";

  const isHub = device["child_device_internal_id"] === "hub";

  const sensorNotOnline = !isHub && deviceOffline;

  if (!isHubOnline && !isHub) {
    return {
      label: "Not connected",
      variant: "error",
    };
  }

  if (isBatteryLow && deviceOffline && !isHub) {
    return {
      label: "Offline - Low battery",
      variant: "offline",
      leftIcon: <BatteryRiskIcon colour={theme.risk3} width="18px" />,
    };
  }

  if (isBatteryLow && !isHub) {
    return {
      label: "Online - Low battery",
      variant: "lowBattery",
      leftIcon: <BatteryRiskIcon colour={theme.unexpected3} width="18px" />,
    };
  }

  if ((isHub && !isHubOnline) || (sensorNotOnline && isHubOnline)) {
    return {
      label: `Offline - ${isDesktop ? "Click" : "Tap"} to fix`,
      variant: "offline",
      leftIcon: <SignalDisconnectedIcon colour={theme.risk3} width="18px" />,
    };
  }

  return {
    label: "Online",
    variant: "online",
    leftIcon: <CircleCheckIcon colour={theme.expected2} width="18px" />,
  };
};
